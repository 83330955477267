import ENVIROMENTS from '../Environment/Configuration.json';
import React, {Component} from "react";
import {useState,useEffect,useContext} from 'react'
import { UserStoreContext } from '../Contexts/UserStoreContext.js';
import {useNavigate, useLocation} from 'react-router-dom';
import Button from '@mui/material/Button';

import Modal from '@mui/material/Modal';

import {PlayContext} from '../Contexts/PlayContext.js';
import {ViewContext} from '../Contexts/ViewContext.js';
export default function BasicModal(props) {
    const location = useLocation();
    const {PlayData} = useContext(PlayContext)
    const {ViewUserData,ViewSongData} = useContext(ViewContext)

    const navigate = useNavigate()


    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
        props.setToggleMenu(false)
    }


    useEffect(()=>{
        handleOpen()
    },[])

    //Check Login Credentials
    const {is_logged_in, user_store} = useContext(UserStoreContext)


    const goto =(link) =>{
        navigate(link)
        handleClose()
    }



  



  return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
      
            <div className="navigationmenu fadefx">
                <div className="contents">
                    {is_logged_in?
                                <div className="profile" >
                                        {user_store.user_avatar.length>1?
                                            <img onClick={()=>goto('/profile/'+user_store.user_id)} className="userpic" src={ENVIROMENTS.baseUrl + "/geniuxmusic/api/media/avatars/smaller/?file_name="+user_store.user_avatar}  />
                                            :
                                            <div onClick={()=>goto('/profile/'+user_store.user_id)} className="userpic">
                                                {user_store.artist_name.substring(0, 1)}
                                            </div>
                                        }
                                            
                                        <div className="titles" onClick={()=>goto('/profile/'+user_store.user_id)}>
                                            <p>{user_store.artist_name}</p>
                                            <p>{user_store.interest}</p>
                                        </div>
                                    </div>
                            :
                            <div className="profile">
                                    <div className="userpic">ME</div>
                                    <div className="titles">
                                        <p>Not logged in</p>
                                        {/* <span>Login/Create an account</span> */}
                                    </div>
                                </div>
                            }
                            {((ViewUserData && location.pathname.includes("/profile")) && ((!is_logged_in) || (is_logged_in && !location.pathname.includes("/profile/"+user_store.user_id)))) &&(
                                        <>
                                            {ViewUserData.platform ==="geniuxmusic" &&(
                                                
                                                <div className='currentview'>
                                                    <Button color="secondary" className={location.pathname.includes("/profile")?"active":""} onClick={()=>goto("/profile/"+ViewUserData.user_id)}>
                                                        <p className="title">{ViewUserData.artist_name}</p>
                                                        <div className='imgicon'>
                                                                {ViewUserData.img_url!==""?
                                                                    <img src={ViewUserData.img_url}/>:
                                                                    <p>{ViewUserData.artist_name.substring(0, 1)}</p>
                                                                }                
                                                        </div> 
                                                    </Button>
                                                </div>
                                            )}
                                            {ViewUserData.platform ==="spotify" &&(
                                                <div className='currentview'>
                                                    <Button color="secondary" className={location.pathname.includes("/profile")?"active":""} onClick={()=>goto("/spotify/profile/"+ViewUserData.user_id)}>
                                                        <p className="title">{ViewUserData.artist_name}</p>
                                                        <div className='imgicon'>
                                                            {ViewUserData.img_url!==""?
                                                                <img src={ViewUserData.img_url}/>:
                                                                <p>{ViewUserData.artist_name.substring(0, 1)}</p>
                                                            }               
                                                        </div> 
                                                    </Button>
                                                </div>
                                            )}
                                        </>
                                            
                                    )}
                                    {(ViewSongData  && location.pathname.includes("/playing"))&&(
                                        <div className='currentview'>
                                            {ViewSongData.platform ==="geniuxmusic" &&(
                                            <Button color="secondary" className={location.pathname.includes("/playing")?"active":""} onClick={()=>goto("/playing/"+ViewSongData.song_id)}>
                                                    <p className="title">{ViewSongData.title}</p>
                                                    <div className='imgicon song'>
                                                         {ViewSongData.img_url!==""?
                                                             <img src={ViewSongData.img_url}/>:
                                                             <img src="/assets/images/defaultsongart.jpg"/>
                                                         }
                                                    </div> 
                                            </Button>
                                            )}
                                            {ViewSongData.platform ==="spotify" &&(
                                                <Button color="secondary" className={location.pathname.includes("/playing")?"active":""} onClick={()=>goto("/spotify/playing/"+ViewSongData.song_id)}>
                                                        <p className="title">{ViewSongData.title}</p>
                                                        <div className='imgicon song'>
                                                            {ViewSongData.img_url!==""?
                                                                <img src={ViewSongData.img_url}/>:
                                                                <img src="/assets/images/defaultsongart.jpg"/>
                                                            }
                                                        </div> 
                                                </Button>
                                            )}
                                        </div>
                                    )}
                                    {(!location.pathname.includes("/playing") && !location.pathname.includes("/profile")  && PlayData)&&(
                                        <div className='currentview'>
                                            
                                            {(PlayData.platform==="geniuxmusic") &&(
                                                <Button color="secondary" className={(location.pathname.includes("/playing") || location.pathname.includes(PlayData.data.song.song_id)) && (!location.pathname.includes('/uploads'))?"active":""} onClick={()=>goto("/playing/"+PlayData.data.song.song_id)}>
                                                        <p className="title">{PlayData.data.song.title}</p>
                                                        <div className='imgicon song'>
                                                             {PlayData.data.song.artcover.length>2 ?
                                                                <img src={ENVIROMENTS.baseUrl + "/geniuxmusic/api/media/arts/smaller/?file_name="+PlayData.data.song.artcover}/>:
                                                                <img src="/assets/images/defaultsongart.jpg"/>
                                                            }
                                                       </div> 
                                                </Button>
                                             )}
                                              {(PlayData.platform==="spotify") &&(
                                                <>
                                                <Button color="secondary" className={location.pathname.includes("/playing") || location.pathname.includes(PlayData.data.song.song_id)?"active":""} onClick={()=>goto("/spotify/playing/"+PlayData.data.song.song_id)}>
                                                        <p className="title">{PlayData.data.song.title}</p>
                                                        <div className='imgicon song'>
                                                            {PlayData.data.song.artcover!=="" ?
                                                                <img src={PlayData.data.song.artcover}/>:
                                                                <img src="/assets/images/defaultsongart.jpg"/>
                                                            }
                                                        </div> 
                                                </Button>
                                                </>
                                            )}
                                            
                                    </div>
                                    )}

                   
                            <Button color="secondary" className={location.pathname=="/browse"?"active":""} onClick={()=>goto('/browse?group=all')}>
                                <p className="title">Search</p>
                            </Button>
                            <Button color="secondary" className={location.pathname=="/"?"active":""} onClick={()=>goto('/')}>
                                <p className="title">Home</p>
                            </Button>

                            {!is_logged_in &&(
                                <Button color="secondary" onClick={()=>goto('/login')}>
                                    <p className="title">Login/Register</p>
                                </Button>
                                
                            )}
                            {is_logged_in &&(
                                    <>
                                    <Button color="secondary" onClick={()=>goto('/profile/'+user_store.user_id)}>
                                        <p className="title">My Profile</p>
                                    </Button>
                                    <Button color="secondary" className={location.pathname=="/notifications"?"active":""} onClick={()=>goto('/notifications')}>
                                            <p className="title">Notifications</p>
                                            {user_store.unseen_notification>0 &&(<div className="mybadge"><p>{user_store.unseen_notification>99?"99":user_store.unseen_notification}</p></div>)}
                                    </Button>
                                    <Button color="secondary" className={location.pathname.includes("/uploads")?"active":""} onClick={()=>goto('/uploads/upload')}>
                                        <p className="title">Upload Music</p>
                                    </Button>
                                    <Button color="secondary" className={location.pathname.includes("/library/liked")?"active":""} onClick={()=>goto('/library/liked')}>
                                        <p className="title">Liked Music</p>
                                    </Button>
                                    <Button color="secondary" className={location.pathname.includes("/library/downloads")?"active":""} onClick={()=>goto('/library/downloads')}>
                                        <p className="title">Downloads</p>
                                    </Button>
                                    <Button color="secondary"  className={location.pathname.includes("/settings")?"active":""} onClick={()=>goto('/settings')}>
                                            <p className="title">Settings</p>
                                    </Button>
                                    </>
                                    
                            )}
                                

                            
                    {ViewUserData && location.pathname.includes("/profile") &&(
                        <>
                            <div className='background'>
                                {ViewUserData.img_url!=="" &&(
                                    <>
                                        <img src={ViewUserData.img_url}/>
                                    </>
                                )}
                            </div>

                              
                        </>
                        
                    )}
            
                    {(ViewSongData && location.pathname.includes("/playing")) &&(
                        <div className='background'>
                            {ViewSongData.img_url!==""?
                            <>
                                <img src={ViewSongData.img_url}/>
                            </>:
                            <>
                                <img src="/assets/images/geniuxbanner.jpeg"/>
                            </>
                                
                            }
                            
                        </div>
                    )}
                    {(!location.pathname.includes("/playing") && !location.pathname.includes("/profile")  && PlayData)&&(
                        
                        <div className='background'>
                            {PlayData.data.song && PlayData.data.song.artcover.length>2?
                            <>
                                <img src={ENVIROMENTS.baseUrl + "/geniuxmusic/api/media/arts/smaller/?file_name="+PlayData.data.song.artcover}/>
                            </>:
                            <>
                                <img src="/assets/images/geniuxbanner.jpeg"/>
                            </>
                                
                            }
                            
                        </div>
                    )}
                    
                    
                    

                </div>
            </div>
         
      </Modal>
  );
}



