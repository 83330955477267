import React, {Component} from "react";
import {useState, useEffect, useContext, useRef, useCallback} from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import NoInternet from '../Dialogs/NoInternet.js';
import FilerDialog from './FilterDialog.js';
import LoadingSkeleton from '../LoadingSkeletons/NotificationsCard.js';
import NotificationCard from '../Cards/NotificationCard.js'
import useFetch from '../APIs/FetchNotifications.js';
import {Link, useNavigate, useSearchParams}  from'react-router-dom';
import Button from '@mui/material/Button';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { UserStoreContext } from '../Contexts/UserStoreContext';
import NavigationMenuToggle from '../Navigation/NavigationMenuToggle.js'

export default function Notifications(props) {
const {is_logged_in, user_store} = useContext(UserStoreContext)
const navigate = useNavigate()
useEffect(()=>{
    window.scrollTo(0, 0)
    document.title = "Notifications";
},[])
    


const [searchParams, setSearchParams] = useSearchParams();
const sort_param = searchParams.get("sort")



//Fetch Notifications
const [page,setPage] = useState(1);

const [data, setData] = useState(null);
const [request, setRequest] = useState(null)
const {data:FetchedData,isLoading, error} = useFetch(request)

useEffect(()=>{
    if(FetchedData && FetchedData.total_results>0){
        if(data && data.length>0){
          setData([...data, ...FetchedData.results])
        }else{
            setData(FetchedData.results)
        }
    }
},[FetchedData])


const send_request =()=>{
    setRequest({
        "sort":sort_param?sort_param:"newest",
        "page":page,
    })
}



useEffect(()=>{
    send_request()
},[page])

useEffect(()=>{
    setData(null)
    setPage(1)
    send_request()
},[sort_param])

const LoadMore = ()=>{
    const currpage = page;
    setPage(currpage+1)
}

const observer = useRef()
const lastBookElementRef = useCallback(node=>{

    if(observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries=>{
        if(entries[0].isIntersecting){

          if(FetchedData && FetchedData.results.length>0){
            LoadMore()
          }
            
        }
    })
    if(node) observer.current.observe(node)
   
},[FetchedData])

//Handle Error Onclick
const handleError = ()=>{
    send_request()
}
const[showNoInternet,setShowInternet] = useState(false)
useEffect(()=>{
    if(error){
        setShowInternet(true)
    }
},[error])

const closeModal = ()=>{
    setShowInternet(false);
}


//
const [isFilterOpened, setFilterOpened] = useState(false)

//Handle Error Onclick



  return (
    <>
    {props.innerWidth<992&&(
        <div className="navbar">
            <div className="wrapper">
                <div className="lft">
                    <IconButton className="icon" onClick={()=>navigate('/')}>
                        <ArrowBackIcon />
                    </IconButton> 
                    <div className="titles">
                        <p>Notifications</p>
                    </div>
                    
                </div>
                <div className="rgt">
                
                <NavigationMenuToggle/>
            
                </div>  
            </div>
            
        </div>
    )}
        


   <div className="mycontainer">
     {/* My  path */}
        {props.innerWidth>992 &&(
            <div className="mypath">
                <div className="wrapper">
                    <div className="lft">
                        {window.history.length>1?
                        <Button onClick={()=>navigate(-1)}>
                            <KeyboardBackspaceIcon/>
                        </Button>  
                        :
                        <Button onClick={()=>navigate(-1)} disabled="true">
                            <KeyboardBackspaceIcon/>
                        </Button>  
                        }
                    </div>
                    <div className="rgt">
                        <p>
                            <Link to="/notifications" className="active">Notifications</Link>
                        </p>
                        {is_logged_in &&(
                        <Link to="/notifications/settings">
                            <IconButton className="icon">
                                <SettingsRoundedIcon />
                            </IconButton>  
                        </Link>
                        )}
                        
                    </div>
                </div>
            </div>
        )}
    
   
        {is_logged_in &&(
            <>
            <div className="wrapper">
                <div className="myfilter">
                    <div className="lft">
                        <p>All Notifications {FetchedData && ("(" + FetchedData.total_results + ")")}</p>
                        <p className='subtitle'>Filtered by: {sort_param==="newest"?"Newest":sort_param==="oldest"?"Oldest":sort_param==="seen"?"Seen first":sort_param==="unseen"?"Unseen first":"Newest"}</p>
                    </div>
                    <div className="rgt">
                        <Button className="loadmorebtn" color="primary" onClick={()=>setFilterOpened(true)}>Filter</Button>
                    </div>
                </div>
          
            {data && (
                data.map((data,index)=>{
                    return <NotificationCard key={index} data = {data} index={index}/>
                }
            ))}
            {(isLoading) &&(
                <>
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                </>
            )}
            <div ref={lastBookElementRef}></div>
    
            {/* Feedbacks */}
            {(!data && error=="network_err") &&(
                <div className="no_data_msg">
                    <div className="container">
                        <div className="icon">
                            <img src="/assets/images/no_notifications_found.svg" />
                        </div>
                        <p>Check your internet connectivity</p> 
                        <Button onClick={()=>send_request()}>
                            Retry
                        </Button>
                    </div>

                </div>
            )}  

            {(FetchedData && FetchedData.total_results<1) &&(
                    <div className="no_data_msg">
                        <div className="container">
                            <div className="icon">
                                <img src="/assets/images/no_notifications_found.svg" />
                            </div>
                            <p>You have no notifications</p> 
                            <Button onClick={()=>navigate(-1)}>
                                Okay
                            </Button>
                        </div>

                    </div>
            )}   
            {(data && showNoInternet)&&(
                <NoInternet closeModal={closeModal} handleError={handleError}/>
            )}
             {isFilterOpened &&(
                <FilerDialog setFilterOpened={setFilterOpened} sort={sort_param}/>
            )}
            </div>
            </>

        )}
        {!is_logged_in &&(
            <div className='wrapper'>
                <div className="no_data_msg">
                    <div className="container">
                        <div className="icon">
                            <img src="/assets/images/no_notifications_found.svg" />
                        </div>
                        <p>You are not logged in, please login or create an account</p> 
                        <Link to="/login">
                            <Button onClick={()=>navigate(1)}>
                                Login/Create an account
                            </Button>
                        </Link>
                        
                    </div>

                </div>
            </div> 
        )}
       
    </div>
    </>

  );
}

